.photo-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.bottom {
  margin-top: 2rem;
  font-size: 1rem;
}
