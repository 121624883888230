.about-center {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 1.25rem;
}

.about-center-mobile {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 1.25rem;
}
